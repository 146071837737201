import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { stepMaster, registerVia } from '../components/global'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Register from './Register';
import * as copy from 'copy-to-clipboard';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import PlanStatus from './PlanStatus';
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import { Modal, ModalBody } from 'react-bootstrap';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import ProfileInterestStatus from '../components/ProfileInterestStatus';
import AddDirectProfileSidebar from '../components/AddDirectProfileSidebar';

export default function AdminDashboard() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  const [ptype, setPtype] = useState("");
  const [riskptype, setRisktype] = useState("0");

  // Read ptype from URL and set it as initial state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ptypeFromUrl = params.get('ptype');

    const risktypeFromUrl = params.get('risktype');

    console.log('ptypeFromUrl', ptypeFromUrl);
    console.log('risktypeFromUrl', risktypeFromUrl);

    if (ptypeFromUrl !== null) {
      setPtype(ptypeFromUrl);
    }

    if (risktypeFromUrl !== null) {
      setRisktype(risktypeFromUrl);
    }

  }, []);



  const loginUser = (id, emailAddress) => {
    axioInstance.get(`admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": emailAddress };
        axioInstance.post(`profile/view`, pdata).then(function (response) {

          sessionStorage.setItem('profile_id', response.data.profile_id);
          sessionStorage.setItem('full_name', response.data.first_name + '\'s');
          sessionStorage.setItem('profile_details_id', response.data.profile_details_id);

          axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'login', profile_id: response.data.profile_id, admin_id: sessionStorage.getItem("admin_id") });

          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  useEffect(() => {
    const loggedInUser = localStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const ptype = params.get('ptype') ? params.get('ptype') : '';
      const risktype = params.get('risktype') ? params.get('risktype') : '';

      const PostData = {
        rel_manager: localStorage.getItem('admin_id'),
        admin_role: localStorage.getItem('admin_role'),
        testProfile: ptype,
        risktype: risktype
      }
      const filteredPostData = Object.fromEntries(
        Object.entries(PostData).filter(([_, value]) => value !== '')
      );

      axioInstance.post(`admin/user-profiles`, filteredPostData).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data);
          setTableRowsData(response.data);
        }
      );

      axioInstance.get(`admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );

      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);

  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const [profileId, setProfileId] = useState();

  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="success" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/*<Dropdown.Item onClick={() => EditRelManager(d.profile_id)}><i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit Rel Manager</Dropdown.Item>*/}

          <Dropdown.Item onClick={() => deleteUser(d.id, d.profile_id)}><i className="fas fa-trash-alt text-danger" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete User</Dropdown.Item>

          <Dropdown.Item onClick={() => RiskLink(d.id, d.enc_profile)}> <i className="fas fa-paste text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Risk Profile Link</Dropdown.Item>
          {
            d.waitlist === 1 && <Dropdown.Item onClick={() => waitlistPopup(d.profile_id)}> <i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Mark Active </Dropdown.Item>
          }
          <Dropdown.Item onClick={() => EditPlanStatus(d.profile_id)}> <i className="fas fa-stream text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Update Plan Status</Dropdown.Item>

          <Dropdown.Item onClick={() => markTestProfile(d.profile_id)}> <i className="fas fa-user-alt-slash text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Mark as Test Profile</Dropdown.Item>
          <Dropdown.Item onClick={() => setDefaultPassword(d.email)}> <i className="fas fa-unlock text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Set Password</Dropdown.Item>
          <Dropdown.Item onClick={() => get_profile_pan(d.profile_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Get PAN</Dropdown.Item>
          <Dropdown.Item onClick={() => { setActive(true); setAction('profileInterest'); setProfileId(d.profile_id) }}> <i className="fas fa-tasks text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Profile Interest </Dropdown.Item>
          <Dropdown.Item onClick={() => getViewLink(d.ref_link)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> View URL</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> <span className='cursor_pointer' onClick={() => loginUser(d.id, d.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span></>,
      width: '90px'
    },
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
      width: '90px'
    },
    {
      name: 'Name | Status',
      selector: row => row.name,
      sortable: true,
      cell: row => {

        let color;
        let status;

        if (row.testProfile === 1) {
          color = 'red';
          status = 'Testing';
        } else if (row.waitlist === 1) {
          color = 'orange';
          status = 'Waitlisted';
        } else {
          color = 'green';
          status = 'Active';
        }
        return <><span>{row.name} <br /> <span style={{ color }}>{status}</span></span></>;
      },
      //width: '190px'
    },
    {
      name: 'Email | Phone',
      selector: row => row.email,
      sortable: true,
      width: '350px', // Set the width of the column
      cell: row => {
        let color;

        if (row.email_verified === "verified") {
          color = 'green';
        } else {
          color = 'red';
        }

        return <span style={{ color }}>{row.email} <br /> {row.phone}</span>;
      }
    },
    {
      name: 'UTM Source | Reg. Via',
      selector: row => row.utm_source,
      sortable: true,
      //width: '190px',
      cell: row => {
        return <span>{row.utm_source ? row.utm_source : 'NA'} <br /> {registerVia(row.registerVia)}</span>;
      }
    },
    {
      name: 'Rel Manager',
      selector: row => row.primary_rel_manager,
      sortable: true,
      //width: '120px'
    },
    {
      name: 'Planner',
      selector: row => row.plan_ser_manager,
      sortable: true,
      //width: '120px'
    },
    {
      name: 'Created By | Created On',
      selector: row => row.admin_name,
      sortable: true,
      //width: '120px',
      cell: row => {
        return <span>{row.admin_name} <br /> {row.create_on}</span>;
      }
    },
    {
      name: 'Stage On | Plan Status',
      selector: row => row.planStatusCurrent,
      sortable: true,
      cell: row => {
        return <span>{stepMaster(row.step)} <br /> {row.planStatusCurrent}</span>;
      }
    }
  ];

  const RiskLink = (id, enc_profile) => {
    const link = 'https://plan.finnovate.in/risk-profile-set?profile_id=' + enc_profile;

    if (copy(link)) {

      const config = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      };

      const postData = { link: link, module_name: 'risk_profile', profile_id: id, created_by: sessionStorage.getItem('admin_id') };
      axioInstance.post(`url-shared`, postData, config).then(
        (response, data) => {

        }
      );

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }


  }

  const UpdateRelManager = () => {
    var postData = {
      'profile_id': profile_id,
      'rel_manager': admin_id
    }
    axioInstance.post(`profile/update-relmanager`, postData)
      .then(function (response) {

        SweetAlert.fire(
          'Profile',
          'Successfully Updated Rel Manager.',
          'success'
        ).then(function () {
          window.location.href = "/admin-dashboard"
        })

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Profile',
          'Failed.',
          'error'
        )
      });
  }


  const getViewLink = (ref_url) => {



    if (ref_url) {

      SweetAlert.fire({
        title: "REF URL",
        text: ref_url ? ref_url : 'NA',
        icon: "success"
      });

    } else {
      SweetAlert.fire({
        title: "REF URL",
        text: "NA",
        icon: "error"
      });
    }




  }

  const get_profile_pan = (profile_id) => {

    axioInstance.post(`profile/pan`, { 'profile_id': profile_id })
      .then(function (response) {

        if (response.data.pan) {

          SweetAlert.fire({
            title: response.data.pan,
            text: "PAN",
            icon: "success"
          });

        } else {
          SweetAlert.fire({
            title: "Error",
            text: "Something went wrong or No PAN Found",
            icon: "error"
          });
        }

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Error',
          'Something went wrong or No PAN Found.',
          'error'
        )
      });


  }

  const setDefaultPassword = (email) => {

    SweetAlert.fire({
      title: "Set Default Password",
      text: "This will change the current password. Continue ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {


          axioInstance.post(`profile/update-password`, { 'email': email, 'confirm_password': 'Finno@1234' }).then(
            function (response) {

              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Successfully Set Default Password - Finno@1234',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert
                    .stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert
                    .resumeTimer)
                }
              }).then(function () {

                window.location.reload();

              });
            }
          )

        }
      });

  }

  const markTestProfile = (profile_id) => {

    SweetAlert.fire({
      title: "Mark as Test Profile",
      text: "Are you sure want to mark as Test profile?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axioInstance.post(`profile/mark-as-testprofile`, { 'profile_id': profile_id }).then(
          function (response) {

            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: 'Successfully Marked Profile as Testing',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert
                  .stopTimer)
                toast.addEventListener('mouseleave', SweetAlert
                  .resumeTimer)
              }
            }).then(function () {

              window.location.reload();

            });
          }
        )
      }
    });

  }

  const deleteUser = (id, profile_id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axioInstance.post(`admin/user-delete`, { 'id': id, 'profile_id': profile_id }).then(
            function (response) {
              SweetAlert.fire(
                'User',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/admin-dashboard";
              });
            }
          )
        }
      });
  }

  /*const EditRelManager = (id) => {
    setProfile_id(id);
    toggleShow();
  }*/



  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };

  const relmanagerFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.admin_id) === parseInt(e.target.value)
      ) {
        return item;
      }


    });


    setTableRowsData(e.target.value > 0 ? searchData : data);
  }

  /*const waitlistFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.waitlist) === parseInt(e.target.value)
      ) {
        return item;
      }


    });


    setTableRowsData(e.target.value < 0 ? data : searchData);
  }*/


  const profileFilter = async (e) => {

    const selectedValue = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('ptype', selectedValue);
    window.location.href = url.toString();

  }

  const riskprofileFilter = async (e) => {

    const selectedValue = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('risktype', selectedValue);
    window.location.href = url.toString();

  }



  const [relManager, setRelManager] = useState([])
  const [profile_id, setProfile_id] = useState();
  const [modal, setModal] = useState(false);
  //const toggleShow = () => setModal(true);
  const toggleClose = () => setModal(false);

  const [admin_id, setadmin_id] = useState();


  const handleChange = (e) => {
    const { value } = e.target;
    setadmin_id(value);
  }


  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }

  const [show, setShow] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);


  const [planStatus, setPlanStatusShow] = useState(false);
  const planStatusClose = () => setPlanStatusShow(false);
  const planStatusShow = () => setPlanStatusShow(true);

  const EditPlanStatus = (id) => {
    setProfile_id(id);
    planStatusShow();
  }

  const [waitlisProfile, setWaitlistProfile] = useState();
  const waitlistPopup = (id) => {
    setShowWaitlist(true);
    setWaitlistProfile(id);
  }

  const updateWaitlist = (id) => {

    axioInstance.post(`profile/update-profile-waitlist`, { 'profile_id': waitlisProfile }).then(
      function (response) {

        SweetAlert.fire({
          toast: true,
          icon: 'success',
          title: 'Successfully Updated Profile',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        }).then(function () {

          window.location.href = "/admin-dashboard";

        });

      }
    )

  }

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const geuestUsersRedirect = () => {
    window.location.href = 'guest-users-dashboard';
    return;
  }

  const planLogsRedirect = () => {
    window.location.href = 'plan-login-logs-dashboard';
    return;
  }

  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }




  return (
    <Fragment>
      <AdminDashboardHeader />

      <Modal show={showWaitlist} onHide={handleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
        <ModalBody>
          <div className="p20">
            <div className="text-center">
              <span className={"addIcon"} />
            </div>
            <div className="text-center mt10 color1e3 fw600 font18">
              {"Waitlisted"}
            </div>
            <div className="text-center mt10 color485 fw500 font14">
              {"Are you sure want to remove from waitlist and active profile"}
            </div>
            <div className="smallbtn mt20">
              <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
              <button type="button" className={"btn btn-danger"} onClick={updateWaitlist}>Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>


      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Register ulogin={1} relManager={sessionStorage.getItem('admin_id')} />
        </Modal.Body>
      </Modal>

      <Modal show={planStatus} onHide={planStatusClose}>
        <Modal.Body>
          <PlanStatus planStatusClose={planStatusClose} profile_id={profile_id} />
        </Modal.Body>
      </Modal>

      <Modal show={modal} onHide={toggleClose}>
        <Modal.Header>{"Assign/Update Rel Manager"}</Modal.Header>
        <Modal.Body>
          <div className='col-md-12'>
            <br />
            <select className="form-control digits" name={"admin_id"} value={admin_id} onChange={handleChange} autoComplete="off">
              <option value="">select</option>
              {relManager.map((data, i) => {
                return (
                  <option value={data.admin_id} key={i}>{data.admin_name}</option>
                )
              })}
            </select>
            <br />
            <button className="btn btn-primary" type='button' onClick={() => UpdateRelManager()}> Update Rel Manager </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">Admin Dashboard</div></div>







          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


            <div className='col-md-3'>
              <div className="font14 color212 fw600">Rel Manager Filter</div>
              <select className="custom-select font14 color212 fw500" name='relManager' onChange={(e) => relmanagerFilter(e)}>
                <option value="">Select Rel Manager</option>
                {relManager.map((data, i) => {
                  return (
                    <option value={data.admin_id} key={i}>{data.admin_name}</option>
                  )
                })}
              </select>

            </div>


            <div className='col-md-2'>
              <div className="font14 color212 fw600">Profile Filter</div>
              <select className="custom-select font14 color212 fw500" name='profileFilter' onChange={(e) => profileFilter(e)}>
                <option value="">Select</option>
                <option value={""} selected={ptype === "" ? "selected" : ""}>{"ALL"}</option>
                <option value={"3"} selected={ptype === "3" ? "selected" : ""}>{"Active"}</option>
                <option value={"2"} selected={ptype === "2" ? "selected" : ""}>{"Waitlisted"}</option>
                <option value={"1"} selected={ptype === "1" ? "selected" : ""}>{"Testing"}</option>
              </select>

            </div>

            <div className='col-md-2'>
              <div className="font14 color212 fw600">Risk Profile Filter</div>
              <select className="custom-select font14 color212 fw500" name='riskprofileFilter' onChange={(e) => riskprofileFilter(e)}>
                <option value="">Select</option>
                <option value={"0"} selected={riskptype === "0" ? "selected" : ""}>{"ALL"}</option>
                <option value={"1"} selected={riskptype === "2" ? "selected" : ""}>{"Done"}</option>
                <option value={"2"} selected={riskptype === "1" ? "selected" : ""}>{"Not Done"}</option>
              </select>

            </div>


          </div>


          <div class="justify-content-left row mt35">
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={addProfile}>Generate Sign In Link</button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addTestProfile') }}> Add Testing Profile </button>
            </div>

            {
              (sessionStorage.getItem('admin_id') === '1' || sessionStorage.getItem('admin_id') === '27') && (<>
                <div class="col-lg-2 col-md-2">
                  <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => geuestUsersRedirect()}>Guest Users</button>
                </div>
                <div class="col-lg-2 col-md-2">
                  <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planLogsRedirect()}>Plan Login Logs</button>
                </div>
              </>


              )
            }





            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addDirectProfile') }}>Add Guest User </button>
            </div>



            {
              sessionStorage.getItem('admin_id') === '1' && (
                <>
                  <div class="col-lg-2 col-md-2">
                    <Dropdown className="">
                      <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots dropdown-toggle btn btn-success">
                        Plan Reports
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                        <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                        <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div class="col-lg-2 col-md-2 mt25">
                    <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planEventsRedirect()}>Plan Events Logs</button>
                  </div>
                </>
              )
            }

          </div>

          <div className="">
            <div className="admin_dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <strong className='font12 mt-3'>Indicators : Verified Email/Mobile - <span className='text-success'>green</span>, Unverified Email/Mobile- <span className='text-danger'>Red</span></strong>
          </div>
        </div>
      </div>

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

      {
        (isActive === true && action === 'addDirectProfile') && (<AddDirectProfileSidebar setActive={setActive} setAction={setAction} />)
      }


      {
        (isActive === true && action === 'profileInterest') && (<ProfileInterestStatus setActive={setActive} setAction={setAction} profileId={profileId} />)
      }

    </Fragment>
  );
}